import React from "react"
import Layout from "../components/layout"

export default function SuccessPage() {
  return (
    <Layout>
      <p>Thank you!</p>
    </Layout>
  )
}
